/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
a {cursor: pointer; text-decoration: unset;}
a:hover {text-decoration: unset;}
.mat-icon {line-height: 30px !important; width: 30px !important; height: 30px !important; font-size: 30px;}
.mat-icon-button {transition:.3s;}
body ::-webkit-scrollbar {width: 10px;}
body ::-webkit-scrollbar-track {box-shadow: inset 0 0 5px grey;border-radius: 10px;}
body ::-webkit-scrollbar-thumb {background: dimgray;border-radius: 10px;}
body ::-webkit-scrollbar-thumb:hover {background: #444;}
body ::-webkit-scrollbar-track-piece {background: #ddd;border-radius: 10px;}
.disable-text-select{-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;}
.mat-checkbox-label{font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; font-size: 15px;}
.mat-form-field-label{font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;}
.mat-input-element{font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;}
.mat-radio-label-content{font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;}
.mat-expansion-panel-header-title{font-family: 'Caslon', Calibri, 'Trebuchet MS', sans-serif;}
.mat-button-wrapper{font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;}
.mat-option-text{font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;}
.mat-dialog-title{font-family: 'Caslon', Calibri, 'Trebuchet MS', sans-serif !important;}
.mat-menu-item{font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; font-size: 18px;}
.mat-select-placeholder{font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;}